import { graphql } from 'gatsby';
import React from 'react';
import { ContactSection } from '../components/ContactSection';
import { Hero, HeroImageFragment, SrcMap } from '../components/Hero';
import { Layout } from '../components/Layout';
import { ContentfulRichText } from '../components/RichText';
import { Section } from '../components/Section';
import heroLg from '../static/training/hero-lg.jpg';
import heroMd from '../static/training/hero-md.jpg';
import heroSm from '../static/training/hero-sm.jpg';
import heroXl from '../static/training/hero-xl.jpg';

interface Props {
  data: {
    service: {
      title: string;
      includeContactForm?: boolean;
      image: HeroImageFragment;
      content: {
        content: string;
      };
      contentTitle: {
        contentTitle: string;
      };
      contentSubtitle: {
        contentSubtitle: string;
      };
    };
  };
}

export default (props: Props) => {
  const { title, image, includeContactForm } = props.data.service;
  const imageMap: SrcMap = {
    sm: image.sm.src,
    md: image.md.src,
    lg: image.lg.src,
    xl: image.xl.src,
  };
  const parsedContent = props.data.service.content
    ? JSON.parse(props.data.service.content.content)
    : {};
  const parsedContentTitle = props.data.service.contentTitle
    ? JSON.parse(props.data.service.contentTitle.contentTitle)
    : {};
  const parsedContentSubtitle = props.data.service.contentSubtitle
    ? JSON.parse(props.data.service.contentSubtitle.contentSubtitle)
    : {};

  return (
    <Layout title={title}>
      <Hero imageSrc={imageMap} title={title} />
      <Section
        alt
        title={<ContentfulRichText node={parsedContentTitle} />}
        subTitle={<ContentfulRichText node={parsedContentSubtitle} />}
      >
        <ContentfulRichText node={parsedContent} />
      </Section>
      {includeContactForm && (
        <Section
          title="Want to talk?"
          subTitle="Contact us today to discuss your specific needs and objectives"
        >
          <ContactSection />
        </Section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query TrainingService {
    service: contentfulService(slug: { eq: "/training" }) {
      title
      includeContactForm
      image {
        ...HeroImage
      }
      content {
        content
      }
      contentTitle {
        contentTitle
      }
      contentSubtitle {
        contentSubtitle
      }
    }
  }
`;
